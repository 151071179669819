<template>
  <en-table :data="data" :method="method" :paging="paging" :pagination="pagination" :height="height || 500" :loading="loading">
    <slot name="prepend"></slot>

    <en-table-column label="颜色代码" prop="colorCode"></en-table-column>
    <en-table-column label="调用次数" prop="referencedCount"></en-table-column>
    <en-table-column label="汽车品牌" prop="brand"></en-table-column>
    <en-table-column label="车型" prop="vehicleSpecName"></en-table-column>
    <en-table-column label="年份" prop="startYear"></en-table-column>
    <en-table-column label="调色日期" prop="preparedDateTime">
      <template #default="{ row }: { row: EnospraySprayDefinitions['SprayFormulaDto'] }">{{ formatDate(row.preparedDateTime) }}</template>
    </en-table-column>
    <en-table-column label="颜色名称" prop="colorName"></en-table-column>
    <en-table-column label="油漆品牌" prop="paintType.message"></en-table-column>
    <en-table-column label="差异色" prop="diffCode"></en-table-column>
    <en-table-column label="工序" prop="procedureType.message"></en-table-column>
    <en-table-column label="色系">
      <template #default="{ row }: { row: EnospraySprayDefinitions['SprayFormulaDto'] }">
        {{ (row.colorFamilies as EnospraySprayDefinitions['LookupDto'][]).map((item) => item.message).join(',') }}
      </template>
    </en-table-column>
    <en-table-column label="颜色效果" prop="colorEffect.message"></en-table-column>
    <en-table-column label="灰度" prop="grayLevel.message"></en-table-column>
    <en-table-column label="工艺" prop="craftType.message"></en-table-column>
    <en-table-column label="调色网点" prop="tenant.shortName"></en-table-column>
    <en-table-column label="调色大师" prop="preparedBy"></en-table-column>
    <en-table-column label="可用评价" prop="mark.message"></en-table-column>
    <en-table-column label="匹配度" prop="matchRating"></en-table-column>
    <en-table-column label="颜色">
      <template #default="{ row }: { row: EnospraySprayDefinitions['SprayFormulaDto'] }">
        <span v-if="row.rgb" :style="{ 'background-color': row.rgb, color: row.rgb }">xxx</span>
      </template>
    </en-table-column>
    <en-table-column label="备注" prop="comment"></en-table-column>

    <slot name="append"></slot>
  </en-table>
</template>

<script lang="ts">
import type { TablePaging } from '@enocloud/components'

export default factory({
  props: {
    data: Array as PropType<EnospraySprayDefinitions['SprayFormulaDto'][]>,
    loading: Boolean,
    method: Function,
    pagination: null,
    paging: Object as PropType<TablePaging>,
    height: Number
  }
})
</script>
